@import '~@amzn/awsui-components-react/sass/constants';

.awsscm-ui {
  .coming-soon {
    width: 50%;
    margin: 0 auto;
  }
  .awsscm-url-deprecation-banner {
      margin-bottom: 1.5rem;
  }
}